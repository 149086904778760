import "vite/modulepreload-polyfill";

import * as Sentry from "@sentry/react";
import { browserTracingIntegration, replayIntegration } from "@sentry/react";
import { QueryCache, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { RouterProvider } from "react-router-dom";

import axios, { AxiosError } from "axios";
import { t } from "i18next";
import { enqueueSnackbar } from "notistack";
import { createRoot } from "react-dom/client";

import "chart.js/auto";

// Note: Remove the following line if you want to disable the API mocks.
import "./mocks";
import { RESPONSE_FORBIDDEN_STATUS } from "utils/constants";
import parseAxiosErrorMessage from "utils/parseAxiosErrorMessage";

import createEmotionCache from "./utils/createEmotionCache";

import { SnackbarMessageType } from "globalTypes";
import { setupI18n } from "i18n";
import getRouter from "routes";

import Providers from "./providers";
import { store } from "./redux/store";
import { Buffer } from "buffer";

import "global.css";

// Polyfill Buffer global
window.Buffer = Buffer;

const container = document.getElementById("root");
const root = createRoot(container!);

function onAxiosError(error: AxiosError<any, any>) {
  if (error?.response?.status === RESPONSE_FORBIDDEN_STATUS) return;
  enqueueSnackbar(error.message, {
    variant: "expandableSnackbar",
    expandedMessage: error.response?.data?.error ?? null,
    fileText: parseAxiosErrorMessage(error),
    type: SnackbarMessageType.ERROR,
    persist: true,
  });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 60,
      useErrorBoundary: true,
    },
  },
  queryCache: new QueryCache({
    onError: (error) => {
      let message = "";
      if (axios.isAxiosError(error)) {
        onAxiosError(error);
        return;
      } else if (typeof error === "string") {
        message = error;
      } else if (error instanceof Error) {
        message = error.message;
      } else {
        message = t("common.genericError");
      }

      enqueueSnackbar(message, {
        variant: SnackbarMessageType.ERROR,
      });
    },
  }),
});

const router = getRouter();
const clientSideEmotionCache = createEmotionCache();

Sentry.init({
  dsn: window._env_.VITE_APP_SENTRY_DSN,
  integrations: [browserTracingIntegration(), replayIntegration()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  // Set the Sentry environment dynamically based on an environment variable
  environment: window._env_.VITE_APP_SENTRY_ENVIRONMENT ?? process.env.NODE_ENV,

  // Disable Sentry when the application is running locally
  enabled: process.env.NODE_ENV !== "development",
});

setupI18n(localStorage.getItem("i18nextLng") ?? "");

root.render(
  <Providers
    queryClient={queryClient}
    reduxStore={store}
    emotionCache={clientSideEmotionCache}
  >
    <ReactQueryDevtools initialIsOpen={false} />
    <RouterProvider router={router} />
  </Providers>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

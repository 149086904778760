import { FC, ReactNode } from "react";

import { Bell } from "react-feather";
import { useTranslation } from "react-i18next";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  SvgIcon,
  ListItemButton,
  Box,
  Button,
} from "@mui/material";

import Avatar, { notificationStatusColours } from "components/avatar";

import { NotificationLevel } from "globalTypes";

import { NotificationProps } from "./types";

export const notificationStatusIcon = (level: NotificationLevel): ReactNode => {
  switch (level) {
    case NotificationLevel.ERROR:
      return <ErrorOutlineIcon />;
    case NotificationLevel.WARNING:
      return <WarningAmberIcon />;
    default:
      return <Bell />;
  }
};

const truncateText = (text: string, maxLength?: number) => {
  if (maxLength && text.length > maxLength) {
    return `${text.slice(0, maxLength)}...`;
  }
  return text;
};

const Notification: FC<Readonly<NotificationProps>> = ({
  notification: { message, level, created, seenAt },
  expandMessage,
  toggleSeen,
  maxMessageLength,
  isNotificationOpen = false,
}) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "component.notification.expanded",
  });
  const userLocale = localStorage.getItem("i18nextLng") ?? navigator.language;

  const displayText = isNotificationOpen
    ? message
    : truncateText(message, maxMessageLength);

  const handleMarkClicked = () => {
    expandMessage();
    toggleSeen();
  };

  return (
    <Box>
      <ListItem
        onClick={expandMessage}
        disableGutters
        disablePadding
        sx={{
          flexDirection: "column",
          ".Mui-selected": {
            backgroundColor: (theme) => theme.palette.background.default,
          },
          ".MuiButtonBase-root": {
            width: "100%",
          },
        }}
      >
        <ListItemButton selected={!!seenAt}>
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Box sx={{ display: "flex" }}>
              <ListItemAvatar>
                <Avatar
                  status={notificationStatusColours[level]}
                  seen={Boolean(seenAt)}
                >
                  <SvgIcon fontSize="small">
                    {notificationStatusIcon(level)}
                  </SvgIcon>
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={new Date(created).toLocaleString(userLocale)}
                primaryTypographyProps={{
                  variant: "subtitle2",
                  sx: { color: (theme) => theme.palette.text.primary },
                }}
                secondary={displayText}
                secondaryTypographyProps={{
                  variant: "body2",
                  sx: { color: (theme) => theme.palette.text.secondary },
                }}
              />
            </Box>
            {isNotificationOpen && !seenAt && (
              <Button onClick={handleMarkClicked}>{t("markAsRead")}</Button>
            )}
          </Box>
        </ListItemButton>
      </ListItem>
    </Box>
  );
};

export default Notification;

import {
  DashboardViewPayloadPost,
  DashboardViewPayloadPut,
  DashboardViewResponse,
  DashboardViewResponseSearch,
} from "domain/entities/dashboardView";

import axiosClient from "./axios";
import { createApiQuery, createSearchApiQuery } from "./queryUtils";

export const dashboardViewApi = {
  createDashboardView: (data: DashboardViewPayloadPost) =>
    axiosClient.post<DashboardViewResponse>("/hive/dashboard/view", data),
  updateDashboardViewById: (
    dashboardViewId: string,
    data: DashboardViewPayloadPut,
  ) =>
    axiosClient.put<DashboardViewResponse>(
      `/hive/dashboard/view/${dashboardViewId}`,
      data,
    ),
  deleteDashboardViewById: (dashboardViewId: string) =>
    axiosClient.delete<DashboardViewResponse>(
      `/hive/dashboard/view/${dashboardViewId}`,
    ),
};

export interface DashboardViewSearchQueryParams {
  name?: string;
  key?: string;
  dashboardId?: string;
  initial?: boolean;
}

export const useDashboardViewSearch = createSearchApiQuery<
  DashboardViewResponseSearch,
  undefined,
  DashboardViewSearchQueryParams
>("/hive/dashboard/view");

export const useDashboardViewById = createApiQuery<
  DashboardViewResponse,
  { dashboardViewId: string }
>("/hive/dashboard/view/{dashboardViewId}");

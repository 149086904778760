import React, { useCallback } from "react";

import { withTheme } from "@emotion/react";
import { Edit2, Maximize } from "react-feather";
import { useTranslation } from "react-i18next";

import { Menu as MenuIcon } from "@mui/icons-material";
import {
  AppBar as MuiAppBar,
  Grid,
  IconButton as MuiIconButton,
  Toolbar,
  Box,
  Tooltip,
  Button,
} from "@mui/material";

import styled from "@emotion/styled";

import { DashboardSelect } from "components/dashboard/dashboardSelect/DashboardSelect";
import ResourceGuard from "components/resourceGuard/ResourceGuard";

import { DashboardContextType } from "layouts/Application";

import { useGetDashboardComponentIdPath } from "hooks/useGetDashboardComponentIdPath";

import NavbarAlarmsDropdown from "./NavbarAlarmsDropdown";
import NavbarBreadcrumb from "./NavbarBreadcrumb";
import NavbarLanguagesDropdown from "./NavbarLanguagesDropdown";
import NavbarNotificationsDropdown from "./NavbarNotificationsDropdown";
import NavbarTenantSelect from "./NavbarTenantSelect";
import NavbarTheme from "./NavbarTheme";
import NavbarUserDropdown from "./NavbarUserDropdown";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Offset = styled("div")(({ theme }) => theme.mixins.toolbar);

interface NavbarProps extends DashboardContextType {
  onDrawerToggle: React.MouseEventHandler<HTMLElement>;
  saveDashboardState: () => void;
  languages?: string[];
}

const UUID_REGEX =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

function isDashboardURL(url: string): boolean {
  try {
    const parsedUrl = new URL(url);
    const pathParts = parsedUrl.pathname.split("/").filter(Boolean);

    if (pathParts.length !== 3 && pathParts.length !== 4) return false;

    if (!["project", "tenant"].includes(pathParts[0])) return false;

    if (!UUID_REGEX.test(pathParts[1])) return false;

    if (pathParts.length === 3) {
      return pathParts[2] === "overview";
    }

    if (
      pathParts[0] === "project" &&
      pathParts[2] === "dashboard" &&
      UUID_REGEX.test(pathParts[3])
    ) {
      return true;
    }

    return false;
  } catch {
    return false;
  }
}

const Navbar: React.FC<NavbarProps> = ({
  onDrawerToggle,
  languages,
  isDashboardEditMode,
  setIsDashboardEditMode,
  isFullScreenDashboard,
  setIsFullScreenDashboard,
  saveDashboardState,
}) => {
  const { t } = useTranslation();
  const isDashboardPage = isDashboardURL(window.location.href);
  const handleFullscreenClick = useCallback(() => {
    if (isDashboardEditMode) setIsDashboardEditMode(false);
    setIsFullScreenDashboard(!isFullScreenDashboard);
  }, [
    isDashboardEditMode,
    isFullScreenDashboard,
    setIsDashboardEditMode,
    setIsFullScreenDashboard,
  ]);
  const pathComponentId = useGetDashboardComponentIdPath();

  const renderDashboardBtns = () => {
    if (!isDashboardEditMode) {
      return (
        <ResourceGuard component-id={pathComponentId?.concat(".editTab")}>
          <Tooltip title={t("component.dashboard.tile.action.edit")}>
            <IconButton
              onClick={() => setIsDashboardEditMode(true)}
              disabled={isDashboardEditMode}
              size="large"
              color="inherit"
            >
              <Edit2 />
            </IconButton>
          </Tooltip>
        </ResourceGuard>
      );
    } else {
      return (
        <Box>
          <ResourceGuard component-id={pathComponentId?.concat(".editTab")}>
            <Button
              onClick={saveDashboardState}
              disabled={!isDashboardEditMode}
              size="small"
              variant="contained"
              sx={{ marginLeft: "0.5rem" }}
            >
              {t("component.dashboard.tile.action.saveDashboardChanges")}
            </Button>
          </ResourceGuard>

          <ResourceGuard component-id={pathComponentId?.concat(".viewTab")}>
            <Button
              onClick={() => setIsDashboardEditMode(false)}
              size="small"
              variant="outlined"
              sx={{ marginLeft: "0.5rem" }}
            >
              {t("component.dashboard.tile.action.cancelDashboardChanges")}
            </Button>
          </ResourceGuard>
        </Box>
      );
    }
  };

  return (
    <>
      <AppBar position="fixed" elevation={0}>
        <Toolbar>
          <Grid
            container
            alignItems="center"
            sx={{ marginLeft: { lg: "258px" } }}
          >
            <Grid item sx={{ display: { xs: "block", lg: "none" } }}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs sx={{ paddingLeft: { lg: "38px" } }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                }}
              >
                <NavbarBreadcrumb />
                {isDashboardPage ? renderDashboardBtns() : null}
              </Box>
            </Grid>
            <Grid item>
              {isDashboardPage && (
                <>
                  <ResourceGuard component-id="tenantOverviewPage.viewTab.selectDashboard">
                    <Tooltip
                      title={t(
                        "component.dashboard.tile.action.selectDashboard",
                      )}
                    >
                      <span>
                        <DashboardSelect />
                      </span>
                    </Tooltip>
                  </ResourceGuard>
                  <Tooltip
                    title={t("component.dashboard.tile.action.fullscreen")}
                  >
                    <IconButton
                      aria-label="Fullscreen"
                      onClick={() => handleFullscreenClick()}
                      size="large"
                      color="inherit"
                    >
                      <Maximize />
                    </IconButton>
                  </Tooltip>
                </>
              )}

              <NavbarTenantSelect />
              <NavbarTheme />
              <NavbarAlarmsDropdown />

              <ResourceGuard component-id="notification">
                <NavbarNotificationsDropdown />
              </ResourceGuard>

              <NavbarLanguagesDropdown languages={languages} />
              <NavbarUserDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Offset />
    </>
  );
};

export default withTheme(Navbar);

import { FC, useCallback, useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";

import { ListItem, ListItemText, useTheme } from "@mui/material";

import { useDashboardInfiniteSearch } from "fetch/dashboard";

import { PayloadGet, ResultType, SearchType } from "globalTypes";

import { VirtualListProps, CustomListDataType } from "./types";

const Item = (
  props: ListChildComponentProps<CustomListDataType<PayloadGet>>,
) => {
  const { index, style, data } = props;
  const [hover, setHover] = useState(false);
  const theme = useTheme();

  const itemStyle = {
    ...style,
    cursor: "pointer",
    backgroundColor: hover
      ? theme.palette.background.paper
      : style.backgroundColor,
  };

  return (
    <ListItem
      style={itemStyle}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={
        data?.items[index] && data.handleSelect
          ? () => data.handleSelect(data.items[index].identifier.id)
          : () => {}
      }
    >
      <ListItemText primary={data.items[index] ? data.items[index].name : ""} />
    </ListItem>
  );
};

const entitiesPerPage = 10;

export const VirtualList: FC<VirtualListProps> = ({ handleSelect }) => {
  const { activeProjectId } = useParams();
  const theme = useTheme();

  const [numberOfRecords, setNumberOfRecords] = useState<number>(0);
  const [listData, setListData] = useState<CustomListDataType<PayloadGet>>({
    handleSelect: handleSelect,
    items: [],
  });

  const { data, fetchNextPage } = useDashboardInfiniteSearch({
    variables: {
      queryParams: {
        projectId: activeProjectId,
        searchType: SearchType.STANDARD,
        resultType: ResultType.COMPLETE,
        entitiesPerPage: entitiesPerPage,
        page: 1,
      },
    },
  });

  useEffect(() => {
    if (data) {
      const newList: typeof listData.items = Array.from({
        length: numberOfRecords,
      });
      for (const param of data.pageParams ?? []) {
        const fetchedData = data.pages[data.pageParams.indexOf(param)];
        setNumberOfRecords(fetchedData?.numberOfRecords ?? numberOfRecords);

        const payload = fetchedData?.payload ?? [];
        newList.splice(
          (fetchedData.page - 1) * fetchedData.recordsPerPage,
          payload.length,
          ...(payload as unknown as []),
        );
      }
      setListData((prevState) => {
        const newState: typeof listData = JSON.parse(JSON.stringify(prevState));
        newState.handleSelect = handleSelect;
        newState.items = newList;
        return newState;
      });
    }
    // we only want to update list when new data arrives
    // eslint-disable-next-line
  }, [data]);

  const loadMoreItems = useCallback(
    async (startIndex: number, stopIndex: number) => {
      let startIndexPage = Math.floor(startIndex / entitiesPerPage);
      const stopIndexPage = Math.floor(stopIndex / entitiesPerPage);
      while (startIndexPage <= stopIndexPage) {
        await fetchNextPage({ pageParam: ++startIndexPage });
      }
    },
    [fetchNextPage],
  );

  return (
    <InfiniteLoader
      isItemLoaded={(index: number) => listData.items[index] !== undefined}
      itemCount={numberOfRecords}
      loadMoreItems={loadMoreItems}
    >
      {({ onItemsRendered, ref }) => (
        <FixedSizeList
          className="List"
          height={250}
          itemCount={numberOfRecords}
          itemSize={30}
          itemData={listData}
          onItemsRendered={onItemsRendered}
          ref={ref}
          width="100%"
          style={{ backgroundColor: theme.palette.background.default }}
        >
          {Item}
        </FixedSizeList>
      )}
    </InfiniteLoader>
  );
};

import { DashboardLayoutWidget } from "domain/entities/dashboardView";
import { WidgetPayloadGet as Widget } from "domain/entities/widget";

type ResizeHandle = "s" | "w" | "e" | "n" | "sw" | "nw" | "se" | "ne";
interface WorkingWidget {
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
  minW?: number;
  maxW?: number;
  minH?: number;
  maxH?: number;
  moved?: boolean;
  static?: boolean;
  isDraggable?: boolean;
  isResizable?: boolean;
  resizeHandles?: ResizeHandle[];
  isBounded?: boolean;
}

const translateToWorkingWidget = (widget: Widget): WorkingWidget => {
  return {
    i: widget.identifier.id,
    w: widget.size.width,
    h: widget.size.height,
    x: widget.position.x,
    y: widget.position.y,
  };
};

const translateToApiWidget = (widget: WorkingWidget): DashboardLayoutWidget => {
  return {
    id: widget.i,
    position: {
      x: widget.x,
      y: widget.y,
    },
    size: {
      width: widget.w,
      height: widget.h,
    },
  };
};

const translateToWorkingLayout = (layout: Widget[]): WorkingWidget[] => {
  const newLayout = layout.map((widget) => {
    return translateToWorkingWidget(widget);
  });

  return newLayout;
};

const translateToApiLayout = (
  widgets: WorkingWidget[],
): DashboardLayoutWidget[] => {
  const widgetsReference = widgets.map((widget) => {
    return translateToApiWidget(widget);
  });

  return widgetsReference;
};

export {
  translateToWorkingLayout,
  translateToApiLayout,
  translateToWorkingWidget,
  translateToApiWidget,
};

import {
  Component,
  FC,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { AlignJustify } from "react-feather";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";

import { Box, IconButton } from "@mui/material";

import styled from "@emotion/styled";

import { VirtualList } from "components/virtualList/VirtualList";

const useOutsideClick = (
  uiHandleRef: RefObject<HTMLElement>,
  callback: () => void,
) => {
  const ref = useRef<Component>(null);

  const containsTarget = (
    ref: RefObject<Component | HTMLElement>,
    target: EventTarget | null,
  ) => {
    return (ref.current as unknown as HTMLElement)?.contains(target as Node);
  };

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (
        !containsTarget(ref, event.target) &&
        !containsTarget(uiHandleRef, event.target)
      ) {
        callback();
      }
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [ref, callback, uiHandleRef]);
  return ref;
};

export const DashboardSelect: FC = () => {
  const { activeProjectId } = useParams();
  const navigate = useNavigate();

  const [open, setOpen] = useState<boolean>(false);

  const uiHandle = useRef<HTMLButtonElement>(null);
  const handleClickOutsideOfComponent = useCallback(() => {
    if (open) {
      setOpen(false);
    }
  }, [open]);
  const ref = useOutsideClick(uiHandle, handleClickOutsideOfComponent);

  const toggleOpen = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const handleDashboardChange = useCallback(
    (id: string) => {
      navigate(`/project/${activeProjectId}/dashboard/${id}`);
    },
    [activeProjectId, navigate],
  );

  const onKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape" && open) {
        setOpen(false);
      }
    },
    [open],
  );

  useEffect(() => {
    document.addEventListener("keydown", onKeyPress, false);

    return () => {
      document.removeEventListener("keydown", onKeyPress, false);
    };
  });

  return (
    <IconButton
      onClick={toggleOpen}
      ref={uiHandle}
      size="large"
      color="inherit"
    >
      <AlignJustify />
      {open && (
        <StyledBox ref={ref}>
          <VirtualList handleSelect={handleDashboardChange}></VirtualList>
        </StyledBox>
      )}
    </IconButton>
  );
};

const StyledBox = styled(Box)({
  position: "absolute",
  height: "100%",
  width: "350px",
  zIndex: "2",
  right: "10px",
  top: "35px",
});
